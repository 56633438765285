<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>{{ $t('table.search.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50" />
            <label>{{ $t('table.search.entries') }}</label>
          </b-col>
          <!-- From Date Field -->
          <b-col cols="3">
            <b-row>
              <b-col cols="2">
                <span class="text-nowrap">{{
                  $t('arl_codes.filter.from')
                }}</span>
              </b-col>
              <b-col cols="10">
                <date-picker
                  :element-required="false"
                  element-field="filter_date_from"
                  :root-element-data="filter"
                  :element-clearable="true" />
              </b-col>
            </b-row>
          </b-col>
          <!-- To Date Field -->
          <b-col cols="3">
            <b-row>
              <b-col cols="2">
                <span class="text-nowrap">{{ $t('arl_codes.filter.to') }}</span>
              </b-col>
              <b-col cols="10">
                <date-picker
                  :element-required="false"
                  element-field="filter_date_to"
                  :root-element-data="filter"
                  :element-clearable="true" />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="4"
            class="d-flex justify-content-start mb-1 mb-md-0">
            <b-form-input
              v-model="query"
              class="d-inline-block mr-1"
              :placeholder="$t('table.search.search')" />
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click="export2excel">
              <span class="text-nowrap">{{ $t('list.export') }}</span>
            </b-button>
            <b-button
              :to="{
                name:
                  'apps-' +
                  $t(this.RESOURCES_ELEMENT_NAME + '.module_name') +
                  '-create',
              }"
              variant="primary">
              <span class="text-nowrap">{{
                $t(RESOURCES_ELEMENT_NAME + '.object.add')
              }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <vue-double-scrollbar v-if="tableDataLoaded">
        <b-table
          ref="refElementsListTable"
          class="position-relative"
          :items="items"
          :fields="tableColumns"
          primary-key="id"
          show-empty
          :empty-text="$t(RESOURCES_ELEMENT_NAME + '.search.not_found')">
          <!-- column: name -->
          <template #cell(name)="data">
            <b-badge
              pill
              class="text-capitalize">
              {{ data.item.name }}
            </b-badge>
          </template>
          <!-- Column: key -->
          <template #cell(generic_name)="data">
            {{ data.item.generic_name }}
          </template>
          <template #cell(cp)="data">
            <span
              :id="`source-positive-${data.item.id}`"
              v-if="is_source_positive(data.item)"
              class="dot_purple"></span>
            <span
              :id="`patient-positive-${data.item.id}`"
              v-if="is_patient_positive(data.item)"
              class="dot_red"></span>

            <!-- ? DOT_SOURCE -->
            <b-tooltip
              :target="`source-positive-${data.item.id}`"
              :title="$t(RESOURCES_ELEMENT_NAME + '.source_positive')"
              triggers="hover"
              :noninteractive="true"
              fallback-placement="counterclockwise">
            </b-tooltip>
            <!-- ? DOT_PATIENT -->
            <b-tooltip
              :target="`patient-positive-${data.item.id}`"
              :title="$t(RESOURCES_ELEMENT_NAME + '.patient_positive')"
              triggers="hover"
              :noninteractive="true"
              fallback-placement="counterclockwise">
            </b-tooltip>
          </template>
          <template #cell(laboratory)="data">
            {{ data.item.laboratory }}
          </template>
          <template #cell(description)="data">
            {{ data.item.description }}
          </template>
          <template #cell(accident_date)="data">
            {{ formatDate(data.item, 'accident_date') }}
          </template>
          <template #cell(report_date)="data">
            {{ formatDate(data.item, 'report_date') }}
          </template>
          <!-- Column: Status -->
          <!-- Column: actions -->
          <template #cell(actions)="data">
            <b-col class="flex flex-row flex-wrap">
              <b-button
                style="margin: 1px"
                variant="primary"
                :id="`view-button-${data.item.id}`"
                :to="{
                  name:
                    'apps-' +
                    $t(RESOURCES_ELEMENT_NAME + '.module_name') +
                    '-view',
                  params: { id: data.item.id },
                }">
                <feather-icon icon="FileTextIcon" />
              </b-button>

              <b-button
                style="margin: 1px"
                variant="primary"
                :id="`edit-button-${data.item.id}`"
                :to="{
                  name:
                    'apps-' +
                    $t(RESOURCES_ELEMENT_NAME + '.module_name') +
                    '-edit',
                  params: { id: data.item.id, origin: 'not-located' },
                }">
                <feather-icon icon="EditIcon" />
              </b-button>

              <b-button
                style="margin: 1px"
                variant="primary"
                :id="`logbook-button-${data.item.id}`"
                :to="{
                  name: 'apps-logbook-list',
                  params: {
                    case_id: data.item.id,
                    origin: 'apps-case-not-located-list',
                  },
                }">
                <feather-icon icon="FileIcon" />
              </b-button>

              <!-- ? DETAILS -->
              <b-tooltip
                :target="`view-button-${data.item.id}`"
                :title="$t('actions.details')"
                triggers="hover"
                :noninteractive="true"
                fallback-placement="counterclockwise">
              </b-tooltip>
              <!-- ? EDIT -->
              <b-tooltip
                :target="`edit-button-${data.item.id}`"
                :title="$t('actions.edit')"
                triggers="hover"
                :noninteractive="true"
                fallback-placement="counterclockwise">
              </b-tooltip>
              <!-- ? LOGBOOK -->
              <b-tooltip
                :target="`logbook-button-${data.item.id}`"
                :title="$t('actions.logbook')"
                triggers="hover"
                :noninteractive="true"
                fallback-placement="counterclockwise">
              </b-tooltip>
            </b-col>
          </template>
        </b-table>
      </vue-double-scrollbar>
      <!-- Pagination -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination
              v-if="tableDataLoaded"
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="total"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18" />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <div>
      <b-modal
        id="modal-1"
        hide-footer
        :title="$t('actions.delete')">
        <template>
          <delete-confirmation
            :value-name="currentSelection.name"
            :resources-element-name="RESOURCES_ELEMENT_NAME"
            class="mt-2 pt-75" />
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DeleteConfirmation from '../../../core/DeleteConfirmation.vue'
import Filters from './filters.vue'
import store from '@/store'
import router from '@/router'
import ElementStoreModule from './storeModule'
import * as XLSX from 'xlsx'
import moment from 'moment'
import DatePicker from '@/views/core/DatePicker.vue'
import SessionStorage from '@/handlers/SessionStorage'

export default {
  components: {
    DatePicker,
    DeleteConfirmation,
    Filters,
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      ELEMENT_APP_STORE_MODULE_NAME: '',
      RESOURCES_ELEMENT_NAME: '',
      items: [],
      total: 1,
      perPage: 5,
      currentPage: this.currentPage,
      currentPath: window.location.pathname,
      perPageOptions: [5, 10, 25, 50, 100],
      query: '',
      filter: {},
      selected_element: {},
      currentSelection: {},
      tableColumns: [],
      tableDataLoaded: false,
    }
  },
  watch: {
    perPage: function (val) {
      this.fetchElements()
    },
    currentPage: function (val) {
      this.$store.commit('caseSearch/setCurrentPage', val)
      this.fetchElements()
    },
    query: function (val) {
      this.fetchElements()
    },
    'filter.filter_date_from': function (val) {
      this.fetchElements()
    },
    'filter.filter_date_to': function (val) {
      this.fetchElements()
    },
  },
  beforeDestroy() {
    this.$root.$off('delete-entity')
  },
  async mounted() {
    this.RESOURCES_ELEMENT_NAME = 'case'
    this.ELEMENT_APP_STORE_MODULE_NAME =
      'app-list-nl-' + this.RESOURCES_ELEMENT_NAME

    // Register module
    if (!store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        this.ELEMENT_APP_STORE_MODULE_NAME,
        ElementStoreModule
      )
    this.search_storage = new SessionStorage('avenir')

    this.filter = { filter_date_from: undefined, filter_date_to: undefined }
    this.tableColumns = [
      {
        key: 'cp',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.identificator'),
        sortable: true,
      },
      {
        key: 'case_number',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.number'),
        sortable: true,
      },
      {
        key: 'patient_identity',
        label: this.$t(
          this.RESOURCES_ELEMENT_NAME + '.object.patient_identity'
        ),
        sortable: true,
      },
      {
        key: 'patient_name',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.patient_name'),
        sortable: true,
      },
      {
        key: 'accident_date',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.accident_date'),
        sortable: true,
      },
      {
        key: 'report_date',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.report_date'),
        sortable: true,
      },
      {
        key: 'arl_name',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.arl_name'),
        sortable: true,
      },
      {
        key: 'case_type_name',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.case_type_name'),
        sortable: true,
      },
      {
        key: 'case_status_name',
        label: this.$t(
          this.RESOURCES_ELEMENT_NAME + '.object.case_status_name'
        ),
        sortable: true,
      },
      {
        key: 'actions',
        label: this.$t(this.RESOURCES_ELEMENT_NAME + '.object.actions'),
        thStyle: 'width: 300px;',
      },
    ]

    var data = JSON.parse(sessionStorage.getItem(this.currentPath))
    if (data != null) {
      this.query = data.query
      this.filter.filter_date_from = data.date_from
      this.filter.filter_date_to = data.date_to
    }
    await this.loadSearch()
    this.fetchElements()

    // this.$root.$on('delete-entity', () => {
    // console.log('eliminando')

    // var _self = this
    // store
    //     .dispatch(this.ELEMENT_APP_STORE_MODULE_NAME + '/delete_element', { id: _self.currentSelection.id })
    //     .then(response => {

    //     _self.$toast({
    //         component: ToastificationContent,
    //         position: 'top-right',
    //         props: {
    //         title: _self.$t('actions.result.delete.title'),
    //         icon: 'SaveIcon',
    //         variant: 'success',
    //         text: _self.$t('actions.result.delete.success'),
    //         },
    //     })
    //     _self.fetchElements()
    //     })
    //     .catch((error) => {
    //     _self.fetchElements()
    //     // _self.$toast({
    //     //   component: ToastificationContent,
    //     //   position: 'top-right',
    //     //   props: {
    //     //     title: _self.$t('actions.result.delete.title'),
    //     //     icon: 'SaveIcon',
    //     //     variant: 'danger',
    //     //     text: _self.$t('actions.result.delete.failure'),
    //     //   },
    //     // })
    //     })
    // })
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.ELEMENT_APP_STORE_MODULE_NAME))
      store.unregisterModule(this.ELEMENT_APP_STORE_MODULE_NAME)
  },
  methods: {
    is_source_positive(element) {
      return element.source_positive
    },
    is_patient_positive(element) {
      return element.patient_positive
    },
    async loadSearch() {
      const search = this.search_storage.getValueStorage()
      this.currentPage = search.page || 1
      this.perPage = search.perPage || 5
      this.query = search.query || null
      this.filter.filter_date_from = search.from || null
      this.filter.filter_date_to = search.to || null
    },
    formatDate(element, field) {
      return moment(element[field], 'DD/MM/YYYY').format('DD/MM/YYYY')
    },
    delete_entity(selected_element) {
      this.currentSelection = selected_element
      this.$root.$emit('bv::show::modal', 'modal-1')
    },
    filterJson(jsonData) {
      var filterList = this.tableColumns.map(function (a) {
        return a.key
      })
      filterList.pop()
      return jsonData.map((v) =>
        filterList.map((j) => {
          return v[j]
        })
      )
    },
    getHeader() {
      const res = {}
      for (var i = 0; i < this.tableColumns.length; i++) {
        res[this.tableColumns[i].key] = this.tableColumns[i].label
      }
      return res
    },
    format(jsonData) {},
    formatItems(items) {
      return items.map((item) => {
        item.id =
          item.source_positive && item.patient_positive
            ? 'Fuente Positiva - Trabajador Positivo'
            : item.source_positive
            ? 'Fuente Positiva'
            : item.patient_positive
            ? 'Trabajador Positivo'
            : ''
        return item
      })
    },
    async export2excel() {
      const filename = this.$t(
        this.RESOURCES_ELEMENT_NAME + '.not_located_xls_name'
      )
      var result = await store.dispatch(
        this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_elements_not_located',
        {
          queryParams: {
            perPage: 10000,
            page: 1,
            query: this.query,
            from:
              this.filter.filter_date_from == 'Invalid date' ||
              this.filter.filter_date_from == 'Fecha inválida'
                ? undefined
                : this.filter.filter_date_from,
            to:
              this.filter.filter_date_to == 'Invalid date' ||
              this.filter.filter_date_to == 'Fecha inválida'
                ? undefined
                : this.filter.filter_date_to,
          },
        }
      )
      const items = this.formatItems(result.data.items)
      items.unshift(this.getHeader())
      const list = this.filterJson(items)
      const data = XLSX.utils.json_to_sheet(list, { skipHeader: true })
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
    fetchElements() {
      sessionStorage.setItem(
        this.currentPath,
        JSON.stringify({
          query: this.query,
          date_from: this.filter.filter_date_from,
          date_to: this.filter.filter_date_to,
        })
      )

      store
        .dispatch(
          this.ELEMENT_APP_STORE_MODULE_NAME + '/fetch_elements_not_located',
          {
            queryParams: {
              perPage: this.perPage,
              page: this.currentPage,
              query: this.query,
              from:
                this.filter.filter_date_from == 'Invalid date' ||
                this.filter.filter_date_from == 'Fecha inválida'
                  ? undefined
                  : this.filter.filter_date_from,
              to:
                this.filter.filter_date_to == 'Invalid date' ||
                this.filter.filter_date_to == 'Fecha inválida'
                  ? undefined
                  : this.filter.filter_date_to,
            },
          }
        )
        .then((response) => {
          this.items = response.data.items
          this.total = response.data.total
          this.tableDataLoaded = true
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.dot_red {
  height: 25px;
  width: 25px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.dot_purple {
  height: 25px;
  width: 25px;
  background-color: purple;
  border-radius: 50%;
  display: inline-block;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
