import axios from '@axios'
import handlerDataSearch from '@/handlers/handlerDataSearch'


export default {
    namespaced: true,
    state: {
	    searchData: null,
	    currentPage: 1,
    },
    getters: {},
    mutations: {
        setSearchData(state, data){
            state.searchData = data
        },
        setCurrentPage(state, page){
            state.currentPage = page
        }
    },
    actions: {
        fetch_elements(ctx, params) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/v1/cases/search`, {params: params.queryParams})
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetch_elements_not_located(ctx, params) {
            const currentParams = handlerDataSearch(params)
            return new Promise((resolve, reject) => {
                axios
                .get('/api/v1/cases/case/case_not_located', { params: currentParams })
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
        delete_element(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                .delete(`/api/v1/cases/case/${id}`)
                .then(response => resolve(response))
                .catch(error => reject(error))
            })
        },
    },
}
